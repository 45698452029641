import React from "react"
import { Layout } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { Link, graphql } from "gatsby"

const Archief = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.nodes
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? "/archief-2011-2017"
      : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <GatsbySeo
        title="Archief"
        description="Archief van zwembadraad blijven spetteren"
      />
      <div className="container prose prose-lg prose-slate mx-auto max-w-5xl">
        <h2>Archief</h2>
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.frontmatter.slug

            return (
              <li key={post.frontmatter.slug}>
                <article
                  className=""
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={post.frontmatter.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small>{post.frontmatter.date}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
        {!isFirst && (
          <Link to={prevPage} rel="prev" className="ml-2">
            ← Vorige
          </Link>
        )}
        {Array.from({ length: numPages }, (_, i) => (
          <Link
            key={`pagination-number${i + 1}`}
            to={`/archief-2011-2017/${i === 0 ? "" : i + 1}`}
            className="ml-2"
          >
            {i + 1}
          </Link>
        ))}
        {!isLast && (
          <Link to={nextPage} rel="next" className="ml-2">
            Volgende →
          </Link>
        )}
      </div>
    </Layout>
  )
}

export default Archief

export const pageQuery = graphql`
  query archiveListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt
        frontmatter {
          slug
          date(formatString: "DD/MM/YYYY")
          title
        }
      }
    }
  }
`
